import React from 'react';
import CopyIcon from '../img/copyIcon.png';
import PostnordIcon from '../img/postnordIcon.png';
import AsendiaIcon from '../img/asendiaIcon.png';
import DHLIcon from '../img/dhlIcon.png';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const NoTrackingData = ({ trackingNumber, trackingCarrier, trackingCarrierIcon }) => {

  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  let carrierIcon = null;
  if (trackingCarrierIcon === 'AsendiaIcon') {
    carrierIcon = <img className='tracking-copy-icon' src={AsendiaIcon} alt="Asendia Icon" style={{ width: '55px', height: '20px' }}
    />;
  } if (trackingCarrierIcon === 'PostnordIcon') {
    carrierIcon = <img className='tracking-copy-icon' src={PostnordIcon} alt="Postnord Icon"
    />;
  }
  if (trackingCarrierIcon === 'DHLIcon') {
    carrierIcon = <img className='tracking-copy-icon' src={DHLIcon} alt="DHL Icon" style={{ maxWidth: '50%' }}
    />;
  }

  const handleCopyClick = () => {
    navigator.clipboard.writeText(trackingNumber);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <div className='tracking-main'>
      <div className='tracking-container'>
        <div className='tracking-error-cont'>
          <div>{t('tracking_order_error')}</div>
        </div>
        <hr className='tracking-lines-no-data'/>
        <div className='tracking-carrier-area'>
          <div className='tracking-number-text'>{t('order_number')}: </div>
          <div className='tracking-number-area'>
            <div className='tracking-number'>{trackingNumber}</div>
              <div style={{backgroundColor: 'black', padding: '10px', width: '42px' }}>
                <img className='tracking-number-copy-icon' src={CopyIcon} alt="Copy Tracking Number" onClick={handleCopyClick} style={{ cursor: 'pointer', backgroundColor: 'black' }}
                />
              </div>
          </div>
          <div style={{display:"flex"}}>
            {isCopied && (
              <div style={{ backgroundColor: '#529F7766', color: 'black', marginTop: '10px', padding: '5px 25px', fontSize: "12px" }}>
                {t('link_copied')}
              </div>
            )}
          </div>
          <div className='carrier-logo-area'>
            <div className='carrier-logo-text'>{t('carrier')}:</div>
            <div className='carrier-logo-icon'>{carrierIcon}</div>
          </div>
          <div className='track-button-area'>
            <a className='track-button-with-carrier' href={trackingCarrier}>{t('carrier_track')}</a>
          </div>
          <hr className='tracking-lines-no-data'/>
        </div>
      </div>
    </div>
  );
};

export default NoTrackingData;
