import { useEffect, useCallback, useState} from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './i18n';
import NoTrackingData from './components/NoTrackingData';
import NoTrackingNumber from './components/NoTrackingNumber';
import TrackingData from './components/TrackingData';

const getPathLanguage = () => {
  const path = window.location.pathname;
  const segments = path.split('/');
  const langCode = segments[1];
  return langCode;
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [trackingData, setTrackingData] = useState(null);
  const [trackingCarrier, setTrackingCarrier] = useState(null);
  const [trackingCarrierIcon, setTrackingCarrierIcon] = useState(null);
  const [fetchDone, setFetchDone] = useState(null);
  const { search } = useLocation();
  const { trackingNumber, JSON:jsonParam } = queryString.parse(search);
  const { i18n } = useTranslation();
  const [browserLanguage] = useState(getPathLanguage());

  useEffect(() => {
    let effectiveLanguage = browserLanguage
    if (/^U.*/.test(trackingNumber)) {
      i18n.changeLanguage('sv');
    } else {
      if (effectiveLanguage === 'dk') {
        effectiveLanguage = 'da';
      }
      if (effectiveLanguage === 'se') {
        effectiveLanguage = 'sv';
      }
      if (effectiveLanguage !== i18n.language) {
        i18n.changeLanguage(effectiveLanguage);
      }
    }
  }, [i18n, browserLanguage, trackingNumber]);

  const getTrackingInformation = (carrier, trackingNumber, lang) => {
    const trackingInfo = {
      postnord: {
        apiUrl: `https://asendiatracking.azurewebsites.net/api/postnordfull?code=tS1Hisghk0bmbjB38m1y816KH7PA0Qtt_rP99BtmG5LeAzFuF0VL9A%3D%3D&tracking=${trackingNumber}&locale=${lang}`,
        carrierUrl: `https://www.postnord.se/vara-verktyg/spara-brev-paket-och-pall?shipmentId=${trackingNumber}`,
        carrierIcon: 'PostnordIcon'
      },
      dhl: {
        apiUrl: `https://asendiatracking.azurewebsites.net/api/dhlfull?code=tzT61L0Yd0lqz9cwyHPElFbEfwXbpkNw_N0tGUzZqtC5AzFuBmCeQw%3D%3D&trackingNumber=${trackingNumber}&language=${lang}`,
        carrierUrl: `https://www.dhl.com/se-en/home/tracking.html?tracking-id=${trackingNumber}`,
        carrierIcon: 'DHLIcon'
      },
      asendia: {
        apiUrl: `https://asendiatracking.azurewebsites.net/api/asendiafull?code=bPjsoAjRnInqcqbd2IAZpSAmLTA2lMO-Xm7D9M4Tc-RkAzFuFeaSiA%3D%3D&tracking=${trackingNumber}&language=${lang}`,
        carrierUrl: `https://track.asendia.com/track/${trackingNumber}`,
        carrierIcon: 'AsendiaIcon'
      }
    }
    return trackingInfo[carrier];
  }

  const getApiData = useCallback( async () => {
    let carrier = null;
    let trackingAPIInfo = null;
    const postnordRegex = /^U.*/;
    const dhlRegex = /^.{15,}$/;

    if (postnordRegex.test(trackingNumber)) {
      carrier = 'postnord'
      trackingAPIInfo = getTrackingInformation(carrier, trackingNumber, 'sv');
    } else if (dhlRegex.test(trackingNumber)) {
      carrier = 'dhl';
      trackingAPIInfo = getTrackingInformation(carrier, trackingNumber, browserLanguage);
    } else {
      carrier = 'asendia';
      trackingAPIInfo = getTrackingInformation(carrier, trackingNumber, i18n.language);
    }
    try {
      const response = await fetch(trackingAPIInfo.apiUrl)
      const data = await response.json();
      setTrackingCarrier(trackingAPIInfo.carrierUrl);
      setTrackingCarrierIcon(trackingAPIInfo.carrierIcon);
      setFetchDone(true);

      if (data.events.length > 0) {
        setTrackingData(data);
      }
    } catch (error) {
      setTrackingCarrier(trackingAPIInfo.carrierUrl);
      setTrackingCarrierIcon(trackingAPIInfo.carrierIcon);
      setFetchDone(true);
    }
  }, [trackingNumber, browserLanguage, i18n.language]);

  useEffect(() => {
    if (!trackingNumber) {
      setIsLoading(false);
    } else if (trackingData) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [trackingData, trackingNumber]);


    useEffect(() => {
      getApiData();
    }, []);

    if (!trackingNumber) {
      return (
        <NoTrackingNumber />
      );
    }
    // code snippet is needed for zendesk app
    if (trackingData) {
      if (jsonParam === 'true') {
        const jsonData = JSON.stringify(trackingData, null, 2);
        return jsonData;
      }
    }
    if (fetchDone && (!trackingData || !trackingData?.status)) {
      return (
      <NoTrackingData trackingNumber={trackingNumber} trackingCarrier={trackingCarrier} trackingCarrierIcon={trackingCarrierIcon}/>
      );
    }

    if (isLoading && !fetchDone) {
      return (
        <div className='spinner-wrapper'>
            <div className='spinner'/>
        </div>
        );
    } else {
    return (
      <TrackingData trackingNumber={trackingNumber} trackingData={trackingData} trackingCarrier={trackingCarrier} trackingCarrierIcon={trackingCarrierIcon}/>
    );
  }
};

export default App;
