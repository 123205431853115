import React from 'react';
import { useTranslation } from 'react-i18next';

const NoTrackingNumber = () => {
  const { t } = useTranslation();

  return (
    <div className='tracking-text-cont'>
      <div className='tracking-error-cont'>
        <div>{t('order_error')}</div>
      </div>
      <hr className='tracking-hr'/>
      <div className='tracking-cs-cont'>
        <div className='cs-header'>{t('cs_header')}</div>
        <div>{t('no_tracking_order_error')}</div>
        <a className='tracking-cs-button' rel="noreferrer" target='_blank' href="https://support.missmary.com/">{t('cs_button')}</a>
      </div>
    </div>
  );
};

export default NoTrackingNumber;
