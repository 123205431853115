import React from 'react';
import { useState } from 'react';
import CopyIcon from '../img/copyIcon.png';
import PostnordIcon from '../img/postnordIcon.png';
import AsendiaIcon from '../img/asendiaIcon.png';
import DHLIcon from '../img/dhlIcon.png';
import { useTranslation } from 'react-i18next';

const TrackingData = ({ trackingNumber, trackingData, trackingCarrier, trackingCarrierIcon }) => {
  const [showAllRows, setShowAllRows] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();

  let carrierIcon = null;
  if (trackingCarrierIcon === 'AsendiaIcon') {
    carrierIcon = <img className='tracking-copy-icon' src={AsendiaIcon} alt="Asendia Icon" style={{ width: '5rem' }}
    />;
  } if (trackingCarrierIcon === 'PostnordIcon') {
    carrierIcon = <img className='tracking-copy-icon' src={PostnordIcon} alt="Postnord Icon"
    />;
  }
  if (trackingCarrierIcon === 'DHLIcon') {
    carrierIcon = <img className='tracking-copy-icon' src={DHLIcon} alt="DHL Icon" style={{ maxWidth: '50%' }}
    />;
  }

  const toggleShowAllRows = () => {
    setShowAllRows(!showAllRows);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(trackingNumber);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const additionalRowsCount = trackingData.events.length - 3;

  return (
    <div className='tracking-main'>
      <div className='tracking-container'>
        <div className='tracking-carrier-area'>
          <div className='tracking-number-text'>{t('order_number')}: </div>
          <div className='tracking-number-area'>
            <div className='tracking-number'>{trackingNumber}</div>
              <div style={{backgroundColor: 'black', padding: '10px', width: '42px' }}>
                <img className='tracking-number-copy-icon' src={CopyIcon} alt="Copy Tracking Number" onClick={handleCopyClick} style={{ cursor: 'pointer', backgroundColor: 'black' }}
                />
              </div>
          </div>
          <div style={{display:"flex"}}>
            {isCopied && (
              <div style={{ backgroundColor: '#529F7766', color: 'black', marginTop: '10px', padding: '5px 25px', fontSize: "12px" }}>
                {t('link_copied')}
              </div>
            )}
          </div>
          <div className='carrier-logo-area'>
            <div className='carrier-logo-text'>{t('carrier')}:</div>
            <div className='carrier-logo-icon'>{carrierIcon}</div>
          </div>
          <div className='track-button-area'>
            <a className='track-button-with-carrier' href={trackingCarrier}>{t('carrier_track')}</a>
          </div>
          <hr className='tracking-lines'/>
        </div>
        <div>
          <div className='tracking-latest-update'>{t('latest_update')}</div>
          <div className='tracking-latest-update-text' >{t('latest_update_text')}</div>
          <div className="tracking-latest-status">{trackingData.status}</div>
        </div>
        <div className='tracking-table-header'>{t('parcel_history')}</div>
        <table className='tracking-table'>
          <tbody>
            {trackingData.events.slice(0, showAllRows ? trackingData.events.length : 3).map((item, index) => (
              <tr key={index}>
                <td className='table-time'>
                  {item.Time.split(',').map((timePart, i) => (
                    <React.Fragment key={i}>
                      {timePart}
                      <br />
                    </React.Fragment>
                  ))}
                </td>
                <td className='table-event'>{item.Event}</td>
                <td className='table-location'>{item.Location.toLowerCase()}</td>
            </tr>
          ))}
          </tbody>
        </table>
        {trackingData.events.length > 3 && !showAllRows && (
            <button id='tracking-table-rows-more-less' onClick={toggleShowAllRows}  style={{ cursor: 'pointer' }}>
              {t('show_all_info')} ({additionalRowsCount})
            </button>
          )}
        {showAllRows && (
          <button id='tracking-table-rows-more-less' onClick={toggleShowAllRows}  style={{ cursor: 'pointer' }}>
            {t('show_less_info')}
          </button>
        )}
    </div>
  </div>
  );
};

export default TrackingData;
